<template>
  <section id="services" class="services-section my-2">
    <div class="services-container">
      <div class="grid-col-2">
        <div class="">

          <div>
            <h1 class="font-weight-bolder my-2">
              Our Services
            </h1>
            <p class=" mr-md-5 pr-md-5">Exxtra is a digital lending platform that offers you
              exxtra cash just when you need it - for your wallet
              or at checkout. Exxtra features resources on rates
              and terms, allowing you to choose a line of credit
              that works best for your lifestyle and help you do more.
            </p>
          </div>
        </div>

        <div class="d-md-flex  align-items-center">
          <div class="services-card-container">

            <!--            1st card-->
            <div id="first-service-card" class="inactive-shadow card services-card "
                 @mouseover="toggleCards('first-service-card', 'first-toggle-button')"
                 @click="() => $router.push({ name: 'nss-exxtra' })"
            >
              <div class="p-1">
                <div>
                  <img src="@/assets/images/exxtra/exxtra-nss.png" alt="nss-exxtra" height="53px">
                </div>
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="pr-5">Starting off after school is tough
                        - we've got your back NSP!</p>
                    </div>
                    <div>
                      <div class="rounded-circle rounded-button services-card w-100">
                        <arrow-right />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            2nd card-->
            <div id="second-service-card" class="inactive-shadow card services-card active"
                 @mouseover="toggleCards('second-service-card', 'second-toggle-button')"
                  @click="() => $router.push({ name: 'exxtra-cash' })"
            >
              <div class="p-1">
                <div>
                  <extra-cash />
                </div>
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="pr-5">Get the EXXTRA cash you need
                        without breaking your savings</p>
                    </div>
                    <div>
                      <div class="rounded-circle rounded-button services-card w-100">
                        <arrow-right />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            3rd card-->
            <div id="third-service-card" class="inactive-shadow card services-card "
                 @mouseover="toggleCards('third-service-card', 'third-toggle-button')"
                  @click="() => $router.push({ name: 'exxtra-checkout' })"
            >
              <div class="p-1">
                <div>
                  <img src="@/assets/images/exxtra/exxtra-checkout.png" height="53px" alt="exxtra-checkout">
                </div>
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <p class="pr-5">No cash, no problem, buy and
                        checkout - Exxtra would handle it</p>
                    </div>
                    <div>
                      <div class="rounded-circle rounded-button services-card w-100">
                        <arrow-right />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <div>
            <div class="d-flex flex-md-column justify-content-center card-buttons-container mt-2 align-items-center">
              <div id="first-toggle-button" class="rounded-circle card-buttons"
                   @mouseover="toggleCards('first-service-card', 'first-toggle-button')"
              >

              </div>

              <div id="second-toggle-button" class="rounded-circle card-buttons active-card-button"
                   @mouseover="toggleCards('second-service-card', 'second-toggle-button')"
              >

              </div>

              <div id="third-toggle-button" class="rounded-circle card-buttons"
                   @mouseover="toggleCards('third-service-card', 'third-toggle-button')"
              >

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import ArrowRight from "./svgs/arrow-right.vue";
import ExtraCash from "./svgs/extra-cash.vue";

export default {
  name: "Services",
  components: { ExtraCash, ArrowRight },
  methods: {
    toggleCards(card, button) {
      const cards = document.getElementsByClassName('active');
      const buttons = document.getElementsByClassName('active-card-button');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < cards.length; i++) {
        cards.item(i).classList.toggle('active')
        buttons.item(i).classList.toggle('active-card-button')
      }

      //
      const cardElement = document.getElementById(card);
      const buttonElement = document.getElementById(button);
      cardElement.classList.toggle('active');
      buttonElement.classList.toggle('active-card-button');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
