<template>
<b-overlay :show="loading">
  <section id="loan-calculator" class="mb-4 pt-5 loan-calculator-section">
    <div class="loan-calculator-section-title-container">
      <h1 class="text-primary loan-calculator-section-title pt-5 mb-2 ">
        Loan Calculator
      </h1>
      <div class="d-flex flex-wrap">
        <div
            v-for="client_group in activeClientGroups"
            :key="client_group._id"
            class="d-flex mr-2 overflow-auto mb-1"
        >
          <div>
            <b-form-radio
              :key="client_group._id"
              v-model="loanData.client_group"
              :value="client_group._id"
              name="client_group"
              :button="true"
              :button-variant="client_group._id === loanData.client_group ? 'primary rounded-pill ' : 'outline-primary rounded-pill '"
            >
              <span class="d-inline-block ">{{ client_group.title }}</span>
            </b-form-radio>
          </div>
        </div>
      </div>
    </div>

    <div class="loan-calculator-container">
        <b-card-body class="loan-calculator-container-card  mt-5">
          <div class="d-flex align-items-center mb-2">
            <h5 class="font-weight-bold p-0 m-0">
              Amount
            </h5>
            
            <div style="max-width: 150px;">
              <cleave
                id="number"
                v-model="amountInput"
                class="form-control text-lg border ml-4 border-primary"
                raw
                :options="amountInputCleaveOptions"
              />
            </div>
            
          </div>

          <div class="px-1 mt-3">
            <vue-slider
              v-model="loanData.amount"
              :contained="true"
              direction="ltr"
              class="mb-1 pr-4"
              :tooltip-formatter="sliderToolTipFormatter"
              v-bind="amountSliderOptions"
              :hide-label="hideSliderLabel"
            />
          </div>

          <div class="mt-3 mb-5 overflow-auto">
            <h5 class="font-weight-bold mb-1">
              Term
            </h5>
            <div v-if="selectedClientGroup" class="d-flex">
              <div
                  v-for="(loan_term, i) in getValueFromSource(selectedClientGroup, 'loan_terms', [])"
                  :key="i"
                  class="d-flex flex-fill mr-1"
                  style="word-break: keep-all"
              >
                <div class="">
                  <b-form-radio
                      :key="i"
                      v-model="loanData.term_id"
                      :value="loan_term.term_id"
                      name="term_id"
                      :button="false"
                      :button-variant="loan_term.term_id === loanData.term_id ? 'primary rounded-pill' : 'outline-primary rounded-pill'"
                  >
                    <span class="d-inline-block ">{{ getLoanTermTitle(loan_term.term_id) }}</span>
                  </b-form-radio>
              </div>
            </div>
            </div>
          </div>
        </b-card-body>
          
        <div class="loan-calculator-section-summary">
            <h4 class="title font-weight-bold">
              Total Loan Cost
            </h4>
            <h1 class="font-weight-bold py-1">
              ¢{{ formatMoney(totalLoanCost) }}
            </h1>

            <div class="loan-term-stats">
              <LoanTermStatCard :label="'Interest Rate'" :value="interestRate.toFixed(2)+'%'" />
              <LoanTermStatCard class="ml-1" :label="'Interest'" :value="interestDue" />
              <LoanTermStatCard class="ml-1" :label="'Monthly Payment'" :value="monthlyPayment" />
            </div>

            <button
              class="request-loan-button"
              @click="$router.push({ name: 'client-new-loan', params: { loan_product_id: loanData.client_group } })"
            >
              Request Loan
            </button>
        </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BAvatar, BCard, BCardBody, BFormRadio, BOverlay } from "bootstrap-vue";
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import { round } from "lodash";

import LoanTermStatCard from "./LoanTermStatCard.vue";

export default {
  name: "LoanCalculator",
  components: {
    ToastificationContent,
    BOverlay,
    BAvatar,
    VueSlider,
    flatPickr,
    vSelect,
    Cleave,
    BCard,
    BCardBody,
    LoanTermStatCard,
    BFormRadio
  },
  props: {
    loanSettingsResponse: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hideSliderLabel: false,
      loading: false,
      testimonials: [],
      amountInput: 0,
      loanData: {
        amount: 0,
        term_id: "",
        client_group: ""
      },
      amountSliderOptions: {
        max: 0,
        min: 0,
        interval: 1,
        tooltip: "always",
        dotSize: 14 * 1.5,
        height: 4 * 2,
        tooltipStyle: { "font-size": "18px" },
        dotStyle: { "background-color": "white", color: "#144F6A", border: "1.5px solid #144F6A" },
        stepStyle: { color: "white", "background-color": "white", "border-radius": "0" }
      },
      minLoanForClientGroup: 300,
      amountInputCleaveOptions: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        onValueChanged: (e) => {
          const { rawValue: amount } = e.target;
          const client_group_minimum = this.getValueFromSource(this.selectedClientGroup, 'meta.minimum_amount', 0);
          const client_group_maximum = this.getValueFromSource(this.selectedClientGroup, 'meta.maximum_amount', 0);

          const amountIsWithinUpperLimit = +amount <= client_group_maximum;
          const amountIsWithinLowerLimit = +amount >= client_group_minimum;

          if (amountIsWithinUpperLimit && amountIsWithinLowerLimit) {
            this.loanData.amount = +amount;
          }
        },
        rawValueTrimPrefix: true,
        prefix: "¢ "
      },
      loanSettings: null
    }
  },
  computed: {
    termData() {
      const { term_id } = this.loanData;
      const term_data = (this.availableLoanTerms || []).find(term => term._id === term_id);
      return term_data || null
    },
    maximumLoanTermMonths() {
      if (this.selectedClientGroup) {
        const number_of_months = this.selectedClientGroup.loan_terms.map((term) => term.number_of_months)
        return Math.max(...number_of_months);
      }
      return 1;
    },
    activeClientGroups() {
      if (this.loanSettings) {
        return this.loanSettings.client_groups.filter(g => g.status === 'active');
      }
      return [];
    },
    selectedClientGroup() {
      return this.activeClientGroups.find(client_group => client_group._id === this.loanData.client_group);
    },
    activeLoanTermsOfSelectedClientGroup() {
      if (this.selectedClientGroup) {
        return this.selectedClientGroup.loan_terms.filter(term => term.is_active);
      }
      return []
    },
    availableLoanTerms() {
      return this.loanSettings && this.loanSettings.loan_terms ? this.loanSettings.loan_terms : [];
    },
    settings() {
      return this.loanSettings ? this.loanSettings.settings : {}
    },
    interestRate() {
      return this.getValueFromSource(this.selectedClientGroup, 'meta.loan_monthly_rate', 0);
    },
    processingFeeRate() {
      return this.getValueFromSource(this.selectedClientGroup, 'meta.loan_processing_fee', 0);
    },
    interestDue() {
      if (!this.termData) return 0;
      const calcRate = this.interestRate * this.termData.number_of_months; 
      return round(parseFloat(+this.loanData.amount * (calcRate / 100)), 2)
    },
    monthlyPayment(){
      if (!this.termData) return 0;
      const calc = (+this.interestDue + +this.loanData.amount) / this.termData.number_of_months;
      return round(parseFloat(calc), 2)
    },
    totalLoanCost(){
      return parseFloat(+this.loanData.amount + +this.interestDue)
    },
  },
  watch: {
    selectedClientGroup: {
      handler() {
        this.updateSliderOptions();
      }, 
      immediate: true
    },
    "loanData.amount": {
      handler(val) {
        this.amountInput = val;
      }
    }
  },
  created() {
    this.setLoanCalculatorSettings()
  },
  methods: {
    getLoanTermTitle(term_id) {
      const match = this.availableLoanTerms.find(term => term_id === term._id)
      return match ? match.title : "";
    },
    sliderToolTipFormatter(value) {
      return `¢ ${this.formatMoney(value, 0)}`;
    },
    updateSliderOptions() {
      const min = this.getValueFromSource(this.selectedClientGroup, 'meta.minimum_amount', 0);
      const max = this.getValueFromSource(this.selectedClientGroup, 'meta.maximum_amount', 0);
      const marks = {
         [min]: `¢ ${min}`,
         [max]: `¢ ${max}`,
      };
      
      this.amountSliderOptions = { ...this.amountSliderOptions, min, max, marks }

      if (this.loanData.amount > max) {
        this.loanData.amount = max;
        this.amountInput = max;
      }
      if (this.loanData.amount < min) {
        this.loanData.amount = min;
        this.amountInput = min;
      }

      if (!this.loanData.term_id) {
        const loan_terms = this.getValueFromSource(this.selectedClientGroup, 'loan_terms', []);
        if (loan_terms.length > 0) {
          this.loanData.term_id = loan_terms[0].term_id;
        }
      }
    },
    async setLoanCalculatorSettings() {
      try {
        this.loanSettings = this.loanSettingsResponse;
        const active_client_groups = this.loanSettings.client_groups.filter((cg) => cg.status === 'active');
        
        if (active_client_groups.length > 0) {
          const selectedClientGroup = active_client_groups[0]
          this.loanData.client_group = selectedClientGroup._id

          const active_loan_terms = selectedClientGroup.loan_terms.filter(term => term.is_active);
          if (active_loan_terms.length > 0) {
            this.loanData.term_id = active_loan_terms[0].term_id;
          }
        }
      } catch (error) {
        const error_message = error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
.blog-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  font-size: 15px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.loan-term-stats {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.loan-calculator-container {
  display: flex;
  justify-content: space-between;
  overflow-wrap: break-word;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
}

.loan-calculator-section {
  min-height: 300px;
  width: 75%;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 6px;
    margin: 0;
    padding-inline: 5px;
  }
}

.loan-calculator-section-title {
  font-weight: 700; 
  font-size: 40px; 
  line-height: 60px;
  flex-basis: 40%;
}

.loan-calculator-section-title-container {
  @media (max-width: 768px) {
    text-align: center;
    padding-left: 10px;
  }
}

.loan-calculator-section-summary {
  background-color: #FFFFFF;
  box-shadow: 0px 4.37879px 21.8939px rgba(0, 0, 0, 0.08); 
  border-radius: 24px; 
  // flex-basis: max-content; 
  flex-basis: 45%;
  padding: 60px 80px;
  padding-right: 30px;
  height: min-content;
  position: relative;

  .title {
    color: #1F1F1F;
  }

  h1 {
    font-size: 50px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    padding: 60px 30px;
  }
}

.request-loan-button {
  background-color: #144F6A;
  color: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  position: absolute;
  bottom: -24px;
  padding: 11.25px 16.875px;
  box-shadow: 0px 6px 30px rgba(20, 78, 106, 0.08);
  border-radius: 5.625px;
  opacity: 0.95;
  outline: none !important;
}

.request-loan-button:hover {
  opacity: 1;
}

.loan-calculator-container-card {
  padding: 0;
  flex-basis: 55% !important;

  h5 {
    color: #1F1F1F;
  }
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
