<template>
  <div class="d-flex flex-column justify-content-between">
    <h4 class="font-weight-light">
        {{ label }}
    </h4>
    <h1 class="font-weight-bold ">
        {{ value }}
    </h1>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number], 
            required: true,
            default: ""
        },
        label: {
            type: String, 
            required: true,
            default: ""
        }
    }
}
</script>

<style scoped>
  h4 {
    font-size: 16px;
  }
  h1 {
    font-size: 24px;
  }
  h1, h4 {
    color: #000000;
  }
</style>
