<template>
  <section id="mobile-menu" class="mobile-menu hidden">
    <div>
      <div class="d-flex justify-content-between mb-5 align-items-center">
        <div class=" ">
          <b-link :to="{ name: 'app-root' }">
            <img src="@/assets/images/exxtra/logo.png" width="120px" />
          </b-link>
        </div>

        <div>
          <button @click="showMobileMenu">
            <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 4L12 12M12 4L4 12L12 4Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div class="text-center pt-5">
        <div v-if="displayLoanCalculator" class="my-5">
          <a href="#loan-calculator" class="links " @click="movetoElement('loan-calculator-link')">
            <h1>Loan Calculator</h1>
          </a>
        </div>
        <div class="my-5">
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <a v-smooth-scroll class="links mx-1 d-flex align-items-center">
                <h1 class="pr-1">Our Services</h1>
                <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.62915 1L6.50012 8L12.3711 1" stroke="#144F6A" stroke-linecap="round" />
                </svg>
              </a>
            </template>
            <div class="p-1 rounded-lg">
              <b-dropdown-item class="font-weight-bold">
                <b-link :to="{ name: 'nss-exxtra'}">
                  Nss Exxtra
                </b-link>
              </b-dropdown-item>
              <b-dropdown-item class="font-weight-bold">
                <b-link :to="{ name: 'exxtra-cash'}">
                  Exxtra Cash
                </b-link>
              </b-dropdown-item>
              <b-dropdown-item class="font-weight-bold">
                <b-link :to="{ name: 'exxtra-checkout'}">
                  Exxtra Checkout
                </b-link>
              </b-dropdown-item>

            </div>
          </b-dropdown>

        </div>
        <div class="my-5">
          <a href="#how-we-work" class="links " @click="movetoElement('how-we-work-link')">
            <h1>How we work</h1>
          </a>
        </div>
        <div class="my-5">
          <a class="links " @click="$router.push({ name: 'stories' })">
            <h1>Stories</h1>
          </a>
        </div>
        <div class="my-5">
          <a href="#faq" class="links " @click="movetoElement('faq-link')">
            <h1>FAQs</h1>
          </a>
        </div>

      </div>

      <div class="my-5">
        <div class="d-flex justify-content-around my-5">
          <div class="login-button">
            <b-link :to="{ name: 'auth-login' }">
              <button class="px-3 py-1">
                Login
              </button>
            </b-link>

          </div>
          <div class="signup-button">
            <b-link :to="{ name: 'auth-register' }">
              <button class="px-3 py-1 text-white">
                Sign Up
              </button>
            </b-link>

          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import { BDropdown, BDropdownItem, BLink, BRow } from "bootstrap-vue";

export default {
  name: "MobileMenu",
  components: {
    BRow,
    BLink,
    BDropdown,
    BDropdownItem
  },
  props: {
    displayLoanCalculator: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showMobileMenu() {
      const menu = document.getElementById('mobile-menu');
      menu.classList.toggle('hidden');
      document.body.classList.toggle('no-scroll');
    },
    movetoElement(id) {
      // console.log(this.$router.currentRoute);
      this.showMobileMenu();
      if (this.$router.currentRoute.name !== 'app-root') this.$router.push({ name: 'app-root' });
      document.getElementById(id)
          .click();
    }
  }

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
