<template>
  <svg width="220" height="50" viewBox="0 0 220 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.581299" width="38.5454" height="39" rx="3.21145" fill="white" />
    <path d="M15.9288 12.524V9.8521H21.2727V28.5813H18.3182V12.524H15.9288Z" fill="#00203E" />
    <rect x="44.9683" y="0.581299" width="38.8379" height="39" rx="3.21145" fill="white" />
    <path d="M57.4287 18.9726C57.4287 16.0095 57.9254 13.6973 58.9188 12.0359C59.9294 10.3574 61.6593 9.5181 64.1085 9.5181C66.5578 9.5181 68.2791 10.3574 69.2725 12.0359C70.2831 13.6973 70.7884 16.0095 70.7884 18.9726C70.7884 21.97 70.2831 24.3165 69.2725 26.0121C68.2791 27.6907 66.5578 28.5299 64.1085 28.5299C61.6593 28.5299 59.9294 27.6907 58.9188 26.0121C57.9254 24.3165 57.4287 21.97 57.4287 18.9726ZM67.9109 18.9726C67.9109 17.5853 67.8167 16.412 67.6283 15.4529C67.457 14.4937 67.0973 13.7144 66.5492 13.1149C66.0011 12.4983 65.1876 12.19 64.1085 12.19C63.0295 12.19 62.2159 12.4983 61.6678 13.1149C61.1197 13.7144 60.7515 14.4937 60.5631 15.4529C60.3918 16.412 60.3062 17.5853 60.3062 18.9726C60.3062 20.4114 60.3918 21.6189 60.5631 22.5951C60.7344 23.5714 61.094 24.3593 61.6421 24.9588C62.2073 25.5582 63.0295 25.858 64.1085 25.858C65.1876 25.858 66.0011 25.5582 66.5492 24.9588C67.1145 24.3593 67.4827 23.5714 67.654 22.5951C67.8253 21.6189 67.9109 20.4114 67.9109 18.9726Z" fill="#00203E" />
    <rect x="90.229" y="0.581299" width="38.8379" height="39" rx="3.21145" fill="white" />
    <path d="M102.689 18.9726C102.689 16.0095 103.186 13.6973 104.18 12.0359C105.19 10.3574 106.92 9.5181 109.369 9.5181C111.819 9.5181 113.54 10.3574 114.533 12.0359C115.544 13.6973 116.049 16.0095 116.049 18.9726C116.049 21.97 115.544 24.3165 114.533 26.0121C113.54 27.6907 111.819 28.5299 109.369 28.5299C106.92 28.5299 105.19 27.6907 104.18 26.0121C103.186 24.3165 102.689 21.97 102.689 18.9726ZM113.172 18.9726C113.172 17.5853 113.077 16.412 112.889 15.4529C112.718 14.4937 112.358 13.7144 111.81 13.1149C111.262 12.4983 110.448 12.19 109.369 12.19C108.29 12.19 107.477 12.4983 106.929 13.1149C106.38 13.7144 106.012 14.4937 105.824 15.4529C105.653 16.412 105.567 17.5853 105.567 18.9726C105.567 20.4114 105.653 21.6189 105.824 22.5951C105.995 23.5714 106.355 24.3593 106.903 24.9588C107.468 25.5582 108.29 25.858 109.369 25.858C110.448 25.858 111.262 25.5582 111.81 24.9588C112.375 24.3593 112.743 23.5714 112.915 22.5951C113.086 21.6189 113.172 20.4114 113.172 18.9726Z" fill="#00203E" />
    <rect x="135.49" y="0.581299" width="38.8379" height="39" rx="3.21145" fill="white" />
    <path d="M147.95 18.9726C147.95 16.0095 148.447 13.6973 149.441 12.0359C150.451 10.3574 152.181 9.5181 154.63 9.5181C157.08 9.5181 158.801 10.3574 159.794 12.0359C160.805 13.6973 161.31 16.0095 161.31 18.9726C161.31 21.97 160.805 24.3165 159.794 26.0121C158.801 27.6907 157.08 28.5299 154.63 28.5299C152.181 28.5299 150.451 27.6907 149.441 26.0121C148.447 24.3165 147.95 21.97 147.95 18.9726ZM158.433 18.9726C158.433 17.5853 158.338 16.412 158.15 15.4529C157.979 14.4937 157.619 13.7144 157.071 13.1149C156.523 12.4983 155.709 12.19 154.63 12.19C153.551 12.19 152.738 12.4983 152.19 13.1149C151.641 13.7144 151.273 14.4937 151.085 15.4529C150.914 16.412 150.828 17.5853 150.828 18.9726C150.828 20.4114 150.914 21.6189 151.085 22.5951C151.256 23.5714 151.616 24.3593 152.164 24.9588C152.729 25.5582 153.551 25.858 154.63 25.858C155.709 25.858 156.523 25.5582 157.071 24.9588C157.636 24.3593 158.004 23.5714 158.176 22.5951C158.347 21.6189 158.433 20.4114 158.433 18.9726Z" fill="#00203E" />
    <rect x="180.751" y="0.581299" width="38.8379" height="39" rx="3.21145" fill="white" />
    <path d="M193.211 18.9726C193.211 16.0095 193.708 13.6973 194.701 12.0359C195.712 10.3574 197.442 9.5181 199.891 9.5181C202.34 9.5181 204.062 10.3574 205.055 12.0359C206.066 13.6973 206.571 16.0095 206.571 18.9726C206.571 21.97 206.066 24.3165 205.055 26.0121C204.062 27.6907 202.34 28.5299 199.891 28.5299C197.442 28.5299 195.712 27.6907 194.701 26.0121C193.708 24.3165 193.211 21.97 193.211 18.9726ZM203.693 18.9726C203.693 17.5853 203.599 16.412 203.411 15.4529C203.239 14.4937 202.88 13.7144 202.332 13.1149C201.784 12.4983 200.97 12.19 199.891 12.19C198.812 12.19 197.998 12.4983 197.45 13.1149C196.902 13.7144 196.534 14.4937 196.346 15.4529C196.174 16.412 196.089 17.5853 196.089 18.9726C196.089 20.4114 196.174 21.6189 196.346 22.5951C196.517 23.5714 196.877 24.3593 197.425 24.9588C197.99 25.5582 198.812 25.858 199.891 25.858C200.97 25.858 201.784 25.5582 202.332 24.9588C202.897 24.3593 203.265 23.5714 203.436 22.5951C203.608 21.6189 203.693 20.4114 203.693 18.9726Z" fill="#00203E" />
  </svg>
</template>

<script>
export default {
  name: "Thousand"
}
</script>

<style scoped>

</style>
