<template>
  <section class="partners-section my-5">
    <div class="d-flex flex-column justify-content-center align-items-center m-auto">
      <h1 class="font-weight-bolder text-center mb-3">
        We are Backed By Strong Partners
      </h1>
      <img src="@/assets/images/exxtra/exxtra-ghipss.png" width="288" alt="ghipps">
    </div>
  </section>

</template>

<script>
export default {
  name: "Partner"
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
