<template>
  <section id="how-we-work" class="simple-section my-5">
    <div class="simple-container">
      <div class="d-md-flex  align-items-center">

        <div class="w-50 steps-pc">
          <loan-steps-pc />
        </div>

        <div class="my-5 flex-fill">
          <h1 class="font-weight-bolder my-1">
            How we make it simple
          </h1>
          <p>Create an account and receive instant feedback. <br>
            Request for funds or shop at your favorite shop whether <br> online/in-store and pay later or in installments with EXXTRA
          </p>
          <div class="d-flex align-items-center mb-1">
              <span class="mr-1 ">
                <Check />

              </span>
            <p class="p-0 m-0">
              No Collateral
            </p>

          </div>
          <div class="d-flex align-items-center mb-1">
              <span class="mr-1">
                <Check />

              </span>
            <p class="p-0 m-0">
              No Credit Score
            </p>

          </div>
          <div class="d-flex align-items-center mb-1">
              <span class="mr-1">

                <Check />
              </span>
            <p class="p-0 m-0">
              No Hidden Charges
            </p>

          </div>

        </div>

        <div class="steps-mobile mt-5 ">
          <loan-steps-mobile />
        </div>



      </div>

    </div>
    <div class="design">
      <div>
        <BackgroundDesign2 />
      </div>
    </div>
  </section>
</template>

<script>
import LoanStepsPc from "./svgs/loan-steps-pc.vue";
import LoanStepsMobile from "./svgs/LoanStepsMobile.vue";
import Check from "./svgs/Check.vue";
import BackgroundDesign2 from "./svgs/BackgroundDesign2.vue";

export default {
  name: "HowWeWork",
  components: { BackgroundDesign2, Check, LoanStepsMobile, LoanStepsPc }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
