<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_48_360)">
      <path
          d="M13.223 7.37675L18.5776 12.3355L13.6189 17.6901M18.5776 12.3355L6.20165 12.8105L18.5776 12.3355Z"
          stroke="white" stroke-width="1.4596" stroke-linecap="round" stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_48_360">
        <rect width="17.5152" height="17.5152" fill="white"
              transform="translate(11.9146 0.196991) rotate(42.8017)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>

export default {

}

</script>
