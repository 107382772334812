<template>
  <div class="footer-section">


    <div class="footer-content">
      <div class="grid-col-2">
        <div>
          <div>
            <img src="@/assets/images/exxtra/logo.png" alt="exxtra-logo" />
          </div>
          <div class="my-md-2">
            <p class="pr-5">
              Exxtra is a digital lending platform that offers you exxtra cash just when you need it - for your wallet or at checkout. Exxtra features resources on rates and terms, allowing you to choose a line of credit that works best for your lifestyle and help you do more.
            </p>
          </div>

          <div class="mt-3">
            <div class="social-links">
              <a href="https://web.facebook.com/exxtragh"
                  target="_blank"
                  rel="noopener noreferrer"
>
                <facebook-image />
              </a>

              <a href="https://twitter.com/TurtleRent"
                 target="_blank"
                 rel="noopener noreferrer"
                 class="mx-2"
>
                <twitter-image />
              </a>

              <a href="https://www.instagram.com/turtlerent.co"
                 target="_blank"
                 rel="noopener noreferrer"
>
                <instagram-image />
              </a>
            </div>

          </div>
        </div>


        <div>
          <div class="grid-col">
            <div class="mr-md-5 ml-md-2 w-50">
              <h2 class="mb-2">
Services
</h2>

              <p>
                <router-link :to="{ name: 'nss-exxtra' }">
                  NSS Exxtra
                </router-link>
              </p>
              <p>
                <router-link :to="{ name: 'exxtra-cash' }">
                  Exxtra Cash
                </router-link>
              </p>
              <p>
                 <router-link :to="{ name: 'exxtra-checkout' }">
                  Exxtra Checkout
                </router-link>
              </p>
            </div>

            <div class="mx-md-5">
              <h2 class="mb-2">
Contact
</h2>
              <p>8th Siaw Street North Legon, Opposite Zamzam Bakery</p>
              <p> 054-663-5002</p>
              <a href="mailto:support@exxtra.app?subject=From Website"><p class="link"> Support@Exxtra.app</p></a>
            </div>

          </div>
        </div>
      </div>

      <hr>
    </div>
    <div class="d-md-flex  justify-content-between mt-3">
      <div class="mr-auto flex-fill text-left mb-3"><p class="copyright">
        © 2021 EXXTRA - All rights reserved.
      </p></div>
      <div class="mr-auto flex-fill policies">
        <div class="d-flex align-items-center justify-content-around">
          <div>
            <b-link :to="{ name: 'privacy' }" target="_blank">
              <p>Privacy Policy</p></b-link>
          </div>
          <div>
            <b-link :to="{ name: 'terms' }" target="_blank">
              <p>Terms & Condition</p>
            </b-link>
          </div>
          <div>
            <div @click="toggleChatwoot()">
              <p>Support</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
} from 'bootstrap-vue';

import InstagramImage from "@/assets/images/exxtra/instagram.svg";
import FacebookImage from "@/assets/images/exxtra/facebook.svg";
import TwitterImage from "@/assets/images/exxtra/twitter.svg";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    TwitterImage,
    FacebookImage,
    InstagramImage
  },
  methods: {
    toggleChatwoot() {
      window.$chatwoot.toggle();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
