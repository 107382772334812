<template>
  <svg width="254" height="414" viewBox="0 0 254 414" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path d="M144.776 0H55.2693L409.384 357.049L494 353.137L354.604 213.251L494 69.4535H409.384L308.628 167.275L286.129 146.244L426.992 0H339.931L242.109 97.3327L144.776 0Z" fill="#ADCD39" />
      <path d="M85.5941 69.4535H0L138.418 209.339L0 346.289H85.5941L182.438 254.337L204.937 273.901L67.0079 413.786H152.602L294.933 273.901L85.5941 69.4535Z" fill="#459042" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BackgroundDesign2"
}
</script>

<style scoped>

</style>
