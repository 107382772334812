<template>
  <b-overlay :show="loading">
    <section id="faq" class="questions-section">
      <div v-if="faqs.length > 0" class="faq-container d-flex justify-content-center align-items-center">
        <div class="">
          <div class="text-center">
            <h1 class="">
              FAQs
            </h1>
          </div>

          <div class="content ">
            <app-collapse v-for="faq in faqs" :key="faq._id">
              <app-collapse-item :title="getValueFromSource(faq, 'title')" class="mt-1 rounded">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="getValueFromSource(faq, 'content')"></div>
              </app-collapse-item>
            </app-collapse>


            <div v-if="totalRecords > 3" class="text-center mt-5">
              <b-link :to="{ name: 'faqs' }">
                <p class="text-white ">
                  See more
                </p>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { BLink, BOverlay } from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "FAQs",
  components: { 
    AppCollapseItem, 
    AppCollapse, 
    BLink,
    BOverlay 
  },
  data() {
    return {
      loading: false,
      searchFilter: '',
      faqs: [],
      currentPage: 1,
      perPage: 3,
      totalRecords: 0,
      rows: 2,
    }
  },
  created() {
    this.fetchAllData();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
        }

        const request = await this.useJwt().sharedService.fetchFaqs(query);
        const { data, pagination } = request.data;
        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.faqs = data
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>

