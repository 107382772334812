<template>
<div class="home-page-wrapper">
  <Header :display-loan-calculator="displayLoanCalculator" />

<!--  mobile menu-->
  <MobileMenu :display-loan-calculator="displayLoanCalculator" />
<!--  this is the a combination of the hero and services section-->
   <section class="position-relative">
     <Hero />
     <Services />
    <div class="position-absolute design">
      <div class="d-none pc">
        <svg width="290" height="414" viewBox="0 0 290 414" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path
                d="M-59.2238 1.52588e-05H-148.731L205.384 357.05L290 353.137L150.604 213.251L290 69.4535H205.384L104.628 167.275L82.1287 146.244L222.992 1.52588e-05H135.931L38.1089 97.3327L-59.2238 1.52588e-05Z"
                fill="#ADCD39"
/>
            <path
                d="M-118.406 69.4535H-204L-65.5822 209.339L-204 346.289H-118.406L-21.5624 254.337L0.93663 273.901L-136.992 413.786H-51.398L90.9327 273.901L-118.406 69.4535Z"
                fill="#459042"
/>
          </g>
        </svg>
      </div>
      <div class="d-none mobile">
        <svg width="144" height="217" viewBox="0 0 144 217" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path
                d="M-39.1505 7.62939e-06H-86.0439L99.4799 187.061L143.811 185.011L70.78 111.724L143.811 36.3873H99.4799L46.6927 87.637L34.9053 76.6183L108.705 7.62939e-06H63.0926L11.8429 50.9934L-39.1505 7.62939e-06Z"
                fill="#ADCD39"
/>
            <path
                d="M-70.1565 36.3873H-115L-42.4817 109.674L-115 181.424H-70.1565L-19.4193 133.249L-7.63193 143.499L-79.894 216.786H-35.0505L39.5178 143.499L-70.1565 36.3873Z"
                fill="#459042"
/>
          </g>
        </svg>
      </div>

    </div>
  </section>

<!--  how we work-->
  <HowWeWork />

  <LoanCalculator v-if="displayLoanCalculator && hasActiveClientGroup" :loan-settings-response="loanSettingsResponse" />
<!--  what customers are saying-->
 <Customers />
<!--  partners section-->
  <Partner />
  <!--  faqs-->
  <FAQs />
<!--apply now -->
  <ApplyNow />


  <Footer />
</div>
</template>

<script>
import {
  BRow,
  BLink,
} from 'bootstrap-vue'

import { get } from "lodash";
import LottieAnimation from 'lottie-web-vue'
import ExtraBannerJSON from "@/assets/lottie-files/ExtraLandingBanner.json";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ExtraCash from "./svgs/extra-cash.vue";
import Header from "./header.vue";
import Footer from "./footer.vue"
import LoanStepsPc from "./svgs/loan-steps-pc.vue";
import LoanStepsMobile from "./svgs/LoanStepsMobile.vue";
import Thousand from "./svgs/Thousand.vue";
import FAQs from "./FAQs.vue";
import ApplyNow from "./ApplyNow.vue";
import Partner from "./Partner.vue";
import Customers from "./Customers.vue";
import HowWeWork from "./HowWeWork.vue";
import Services from "./Services.vue";
import Hero from "./Hero.vue";
import MobileMenu from "./MobileMenu.vue";
import LoanCalculator from "./LoanCalculator.vue";

export default {
  components: {
    MobileMenu,
    Hero,
    Services,
    HowWeWork,
    Customers,
    Partner,
    ApplyNow,
    FAQs,
    Thousand,
    LoanStepsMobile,
    LoanStepsPc,
    ExtraCash,
    Header,
    Footer,
    BRow,
    BLink,
    LottieAnimation,
    LoanCalculator
  },
  data() {
    return {
      animationData: ExtraBannerJSON,
      loanSettingsResponse: {},
      displayLoanCalculator: false,
      hasActiveClientGroup: null
    }
  },
  created() {
    this.fetchLoanCalculatorSettings()
  },
  methods: {
    showMobileMenu() {
      const menu = document.getElementById('mobile-menu');
      menu.classList.toggle('hidden');
      document.body.classList.toggle('no-scroll');
    },
    async fetchLoanCalculatorSettings() {
      try {
        this.loading = true;
        const response = await this.useJwt().sharedService.fetchLoanSettings();
        const { data } = response.data;
        this.loanSettingsResponse = data
        this.displayLoanCalculator = get(this.loanSettingsResponse, "settings.loan_settings.display_loan_calculator");

        const client_groups = get(this.loanSettingsResponse, "client_groups");
        this.hasActiveClientGroup = client_groups.find(client_group => client_group.status === "active");
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    }
  }


}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
