<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.858643" width="24" height="24" rx="4" fill="#EEF7EE" />
    <g clip-path="url(#clip0_146_161)">
      <path d="M18 5.35864L8.7 15.5859L6 13.5409H4.5L8.7 20.3586L19.5 5.35864H18Z" fill="#459042" />
    </g>
    <defs>
      <clipPath id="clip0_146_161">
        <rect width="16" height="16" fill="white" transform="translate(4 4.85864)" />
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: "Check"
}
</script>

<style scoped>

</style>
