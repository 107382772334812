<template>
<b-overlay :show="loading">
  <section v-if="testimonials.length > 0" class="customers-section d-flex justify-content-start w-100">
    <div class="customers-content">
      <div class="title my-5 ml-5">
        <h1 class="font-weight-bolder text-white ">
          What our Customers say about us
        </h1>
          <p class="text-white lead subtitle">
          Don’t just take our word for it. Thousands of individuals trust Exxtra to do more.
        </p>
      </div>
    </div>

    <div id="testimonialScroller" class="testimonial-carousel">
      <div v-for="(testimony, i) in testimonials" :key="i" class="card customer-card bg-white mr-2">
        <div class="card-body">
          <div class="d-flex flex-column justify-content-between">
            <div>
              <div>
                <svg width="18" height="16" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.64 1.2L3.16 5.96L1.92 6.28C2.05333 5.98666 2.21333 5.74666 2.4 5.56C2.61333 5.37333 2.85333 5.28 3.12 5.28C3.68 5.28 4.18667 5.50666 4.64 5.96C5.12 6.41333 5.36 7 5.36 7.72C5.36 8.44 5.09333 9.08 4.56 9.64C4.05333 10.1733 3.44 10.44 2.72 10.44C2 10.44 1.37333 10.1733 0.84 9.64C0.306667 9.10667 0.0400001 8.46667 0.0400001 7.72C0.0400001 7.4 0.106667 7.04 0.24 6.64C0.373333 6.21333 0.6 5.68 0.92 5.04L3.6 0.079998L5.64 1.2ZM13 1.2L10.52 5.96L9.28 6.28C9.41333 5.98666 9.57333 5.74666 9.76 5.56C9.97333 5.37333 10.2133 5.28 10.48 5.28C11.04 5.28 11.5467 5.50666 12 5.96C12.48 6.41333 12.72 7 12.72 7.72C12.72 8.44 12.4533 9.08 11.92 9.64C11.4133 10.1733 10.8 10.44 10.08 10.44C9.36 10.44 8.73333 10.1733 8.2 9.64C7.66667 9.10667 7.4 8.46667 7.4 7.72C7.4 7.4 7.46667 7.04 7.6 6.64C7.73333 6.21333 7.96 5.68 8.28 5.04L10.96 0.079998L13 1.2Z" fill="#459042" />
                </svg>
              </div>
              <div class="my-1">
                <p class="blog-content-truncate">
                  {{ extractTextContentFromHTML(getValueFromSource(testimony, 'message')) }}
                </p>
              </div>
            </div>

            <div>
              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <div v-if="testimony.avatar && testimony.avatar.path" class="mr-2">
                    <div class="rounded-circle">
                      <img :src="testimony.avatar.path" class="rounded-circle" width="45px" height="45px">
                    </div>
                  </div>
                  <div v-else class="mr-2">
                    <b-avatar :text="avatarText(`${testimony.full_name}`)" :variant="`light-success`" size="45" />
                  </div>
                  <div>
                  </div>
                  <div>
                    <h4 class="font-weight-bold m-0 p-0">
                      {{ testimony.full_name }}
                    </h4>
                    <p class="m-0 p-0">
                      {{ testimony.group }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="design">
      <svg width="350" height="350" viewBox="0 0 416 414" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M66.7762 0H-22.7307L331.384 357.049L416 353.137L276.604 213.251L416 69.4535H331.384L230.628 167.275L208.129 146.244L348.992 0H261.931L164.109 97.3327L66.7762 0Z" fill="#ADCD39" />
        <path d="M7.59406 69.4534H-78L60.4178 209.339L-78 346.289H7.59406L104.438 254.337L126.937 273.901L-10.9921 413.786H74.602L216.933 273.901L7.59406 69.4534Z" fill="#459042" />
      </svg>
    </div>
  </section>
</b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  get
} from "lodash";
import {
  BAvatar,
  BOverlay
} from "bootstrap-vue";

export default {
  name: "Customers",
  components: {
    ToastificationContent,
    BOverlay,
    BAvatar,
  },
  data() {
    return {
      loading: false,
      testimonials: [],
    }
  },
  mounted() {
    this.fetchTestimonials();
  },
  methods: {
    async fetchTestimonials() {
      try {
        this.loading = true;
        const request = await this.useJwt().sharedService.fetchTestimonials();
        const {
          data: testimonials
        } = request.data;
        this.testimonials = testimonials
        
        this.$nextTick(() => {
          this.scroll()
        })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    scroll() {
      const scrollerContainer = document.getElementById('testimonialScroller');
      const { 
        scrollWidth,
        offsetWidth
      } = scrollerContainer;

      const scrollEnd = scrollWidth - offsetWidth;

      const intervalId = setInterval(() => {
        if (scrollerContainer.scrollLeft !== scrollEnd) {
          // scrollerContainer.scrollTo(scrollerContainer.scrollLeft + 0.5, 0);
          scrollerContainer.scroll({
            top: 0,
            left: scrollerContainer.scrollLeft + 0.5,
            behavior: "smooth" })
        } else {
          scrollerContainer.scrollTo(0, 0);
          clearInterval(intervalId);
          this.scroll();
        }
      }, 20);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
.blog-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  font-size: 15px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
