<template>
  <div>
    <div class="position-relative wrapper">
      <div id="first-expansion" class="position-absolute hide showing position-top-0">
        <div class="expansion p-2">
          <div>
            <p class="expansion-title">
              Login/Sign Up
            </p>
          </div>
          <div>
            <p class="expansion-detail">
              Register and fill in your personal information in less than 5 minutes
            </p>
          </div>
        </div>
      </div>
      <div id="second-expansion" class="position-absolute hide  position-top-0">
        <div class="expansion p-2">
          <div>
            <p class="expansion-title">
              Select amount and duration
            </p>
          </div>
          <div>
            <p class="expansion-detail">
              Enter the preferred amount of loan within the approved loan limit.
            </p>
          </div>
        </div>
      </div>
      <div id="third-expansion" class="position-absolute hide  position-top-0">
        <div class="expansion p-2">
          <div>
            <p class="expansion-title">
              Enjoy your stable finances
            </p>
          </div>
          <div>
            <p class="expansion-detail">
              Provide all other required information and say goodbye to low cash.
            </p>
          </div>
        </div>
      </div>
      <svg class="position-absolute  position-top-0" width="100%" height="227" viewBox="0 0 388 227" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="38.0857" cy="207.886" r="10" transform="rotate(-90 38.0857 207.886)" fill="#459042" @click="toggleRings('first-ring','first-expansion')" />
        <circle id="first-ring" class="hide circled ring" cx="37.5857" cy="208.386" r="17.5" transform="rotate(-90 37.5857 208.386)"
                stroke="#459042" stroke-width="2"
        />

        <path d="M40.0857 207.386L367.086 207.386" stroke="#459042" stroke-width="2" stroke-linecap="round"
              stroke-dasharray="4 4"
        />
        <path d="M40.0857 207.386H100.086" stroke="#459042" stroke-width="2" stroke-linecap="round" />

        <circle cx="200.086" cy="207.886" r="10" transform="rotate(-90 200.086 207.886)" fill="#459042" @click="toggleRings('second-ring', 'second-expansion')" />
        <circle id="second-ring" class="hide ring" cx="199.586" cy="208.386" r="17.5" transform="rotate(-90 199.586 208.386)"
                stroke="#459042" stroke-width="2"
        />

        <circle cx="359.086" cy="207.886" r="10" transform="rotate(-90 359.086 207.886)" fill="#459042" @click="toggleRings('third-ring', 'third-expansion')" />
        <circle id="third-ring" class="hide ring" cx="358.586" cy="208.386" r="17.5" transform="rotate(-90 358.586 208.386)"
                stroke="#459042" stroke-width="2"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoanStepsMobile",
  methods: {
    toggleRings(ring, card) {
      const circles = document.getElementsByClassName('circled');
      const cards = document.getElementsByClassName('showing');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < circles.length; i++) {
        circles.item(i).classList.toggle('circled');
        cards.item(i).classList.toggle('showing')
      }

      //
      const element = document.getElementById(ring);
      const cardElement = document.getElementById(card);
      element.classList.toggle('circled');
      cardElement.classList.toggle('showing');
    }
  }
}
</script>

<style scoped>

.wrapper {
  height: 200px;
}

.expansion {
  background-color: #459042;
  color: white !important;
  border-radius: 10px;
}

.expansion-title  {
  font-weight: 400;
  font-size: 18px;
}

.expansion-detail {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
}

circle {
  cursor: pointer;
}

.hide {
  display: none;
}

.circled {
  display: block !important;
}

.showing {
  display: block !important;
}
</style>
