<template>
<section>
  <b-row class="title-section">
    <div>
      <h3 class="mb-1 font-weight-bold">
        Amazing Rates. Faster Approvals </h3>
      <h1 class="font-weight-bold p-0 m-0">
        Do more with <span class=" font-weight-bolder m-0 p-0">exxtra</span>
      </h1>

      <b-link :to="{ name: 'auth-login' }" class="get-started-btn mt-2">
        <span class="ml-1">Get Started</span> <img class="mx-1" src="@/assets/images/exxtra/arrow-right.svg" alt="" srcset="">
      </b-link>
    </div>

    <div>

      <lottie-animation ref="anim" :animation-data="animationData" :loop="true" :auto-play="true" :speed="1" />
    </div>
  </b-row>
</section>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import ExtraBannerJSON from "@/assets/lottie-files/ExtraLandingBanner.json";
import { BLink, BRow, } from 'bootstrap-vue'

export default {
  name: "Hero",
  components: {
    BRow,
    BLink,
    LottieAnimation
  },
  data() {
    return {
      animationData: ExtraBannerJSON
    }
  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
