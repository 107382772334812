<template>
  <section class="apply-section">
    <div class="d-flex apply-pc">
      <div class="first position-relative">
        <img src="@/assets/images/exxtra/map-apply-now.png" alt="exxtra-map" width="710px">
        <div class="position-absolute position-top-0 position-right-0 ">
          <svg width="710" height="588" viewBox="0 0 710 588" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="710" height="588" fill="url(#paint0_linear_150_208)" />
            <defs>
              <linearGradient id="paint0_linear_150_208" x1="693" y1="294" x2="560.5" y2="294" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00203E" />
                <stop offset="1" stop-color="#00203E" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div class="second flex-fill">
        <div class="d-flex h-100 align-items-center">
          <div class="ml-5">
            <h1 class="mb-4">Join over <span class="mx-1 mb-1">

              <Thousand />
            </span> <br> Ghanaians  using Exxtra to <br> do more, today ! </h1>
            <div>
              <b-link :to="{ name: 'auth-register' }">
                <button class="px-3 py-1">
                  Apply Now
                </button>
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="apply-mobile">
      <div class="d-flex flex-column">
        <div class="w-100 position-relative overflow-hidden">
          <img src="@/assets/images/exxtra/map-apply-now.png" alt="exxtra-map" width="100%">
          <div class="position-absolute position-top-0 overflow-hidden">
            <svg width="428" height="356" viewBox="0 0 428 356" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="355.038" width="354.456" height="428" transform="rotate(-90 0 355.038)" fill="url(#paint0_linear_214_497)" />
              <defs>
                <linearGradient id="paint0_linear_214_497" x1="345.969" y1="569.038" x2="279.821" y2="569.038" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#00203E" />
                  <stop offset="1" stop-color="#00203E" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="position-absolute position-bottom-0 overflow-hidden">
            <svg width="428" height="356" viewBox="0 0 428 356" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="428" y="0.581299" width="354.456" height="428" transform="rotate(90 428 0.581299)" fill="url(#paint0_linear_214_496)" />
              <defs>
                <linearGradient id="paint0_linear_214_496" x1="773.969" y1="214.581" x2="707.821" y2="214.581" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#00203E" />
                  <stop offset="1" stop-color="#00203E" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>

          </div>
        </div>
        <div class="join">
          <h1 class="mb-3 mt-3 text-center">Join over <br> <span>
            <Thousand />
          </span> <br> Ghanaians using Exxtra to <br> do more, today ! </h1>
          <div class="text-center mb-4">
            <button class="px-3 py-1">
              Apply Now
            </button>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BLink } from "bootstrap-vue";
import Thousand from "./svgs/Thousand.vue";

export default {
  name: "ApplyNow",
  components: {
    Thousand,
    BLink,
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>


